import logo from './programmer.json';
import Lottie from "lottie-react";
import './App.css';
import { Card,  InputNumber } from 'antd';
import { useState } from 'react';

function App() {
  const [rate, setRate] = useState(6.8);
  const [totalRMB, setTotalRMB] = useState(0);
  const [totalUSD, setTotalUSD] = useState(0);
  const [partUSD, setPartUSD] = useState(0);

  const [fiveRMB, setFiveRMB] = useState(0);
  const [fiveUSD, setFiveUSD] = useState(0);

  const onChangeRate = (value) => {
    console.log('onChangeRate value', value);
    setRate(value);
  }

  const onChangeTotalRMB = (value) => {
    console.log('onChangeTotalRMB value', value);
    setTotalRMB(value);

    setTotalUSD((value / rate).toFixed(2));
    setPartUSD((value / rate / 176).toFixed(2));
  }

  const onChangeFiveRMB = (value) => {
    console.log('onChangeFiveRMB value', value);
    setFiveRMB(value);

    setFiveUSD((value / rate).toFixed(2));
  }

  return (
    <div className="App">
      <Lottie animationData={logo} className="App-logo" />
      <Card className="App-container">
        <div className='List' >
          <div className='List-Item' >
            <span className='List-Item-Label' >汇率</span>
            <InputNumber className='List-Item-Value' placeholder="请输入" value={rate} onChange={onChangeRate} />
          </div>
        </div>
        <div className='List' >
          <div className='List-Item' >
            <span className='List-Item-Label' >工资人名币总金额</span>
            <InputNumber className='List-Item-Value' placeholder="请输入" addonBefore={"¥"} value={totalRMB} onChange={onChangeTotalRMB} />
          </div>
          <div className='List-Item' >
            <span className='List-Item-Label'>美元分算</span>
            <InputNumber disabled addonBefore={"$ 176 X "}  className='List-Item-Value' placeholder="" value={partUSD} />
          </div>
          <div className='List-Item' >
            <span className='List-Item-Label' >工资美元总金额</span>
            <InputNumber disabled className='List-Item-Value' placeholder="" addonBefore={"$"} value={totalUSD} />
          </div>
        </div>
        <div className='List' >
          <div className='List-Item' >
            <span className='List-Item-Label' >五险一金人民币总金额</span>
            <InputNumber addonBefore={"¥"} className='List-Item-Value' placeholder="请输入" value={fiveRMB} onChange={onChangeFiveRMB} />
          </div>
          <div className='List-Item' >
            <span className='List-Item-Label' >五险一金美元总金额</span>
            <InputNumber addonBefore={"$"} disabled className='List-Item-Value' placeholder="" value={fiveUSD} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default App;
